import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import Slider from "rc-slider"
import { Helmet } from "react-helmet"
import "rc-slider/assets/index.css"
import "../styles/global.css"
import { StaticImage } from "gatsby-plugin-image"

// import CameraFrame from "../assets/kamera.png"
import { ApertureIcon, IsoIcon, ExposureIcon } from "../components/Icons"
import combinations from "../assets/kameran_asetusten_kombinaatiot.json"

import VideoBlur from "../assets/Revontulikamera_loop_TAUSTA_BLUR_PAKATTU.mp4"
import Video from "../assets/Revontulikamera_loop_PAKATTU.mp4"

import { css } from "styled-components"

import V0_Oikea_valotus from "../assets/result-images/V0_Oikea_valotus.jpg"
import V0_Oikea_valotus2 from "../assets/result-images/V0_Oikea_valotus2.jpg"
import VAli2VoimakasAlivalotus from "../assets/result-images/V-ALI2_Voimakas_alivalotus.jpg"
import VAli1KevytAlivalotus from "../assets/result-images/V-ALI1_Kevyt_alivalotus.jpg"
import VAli2KevytAlivalotus from "../assets/result-images/V-ALI1_Kevyt_alivalotus2.jpg"
import VYli1 from "../assets/result-images/V-YLI_Ylivalotus.jpg"
import VYli2 from "../assets/result-images/V-YLI_Ylivalotus2.jpg"
import VISO2_Korkea_ISO_alivalotettu from "../assets/result-images/V-ISO2_Korkea_ISO_alivalotettu.jpg"
import VISO_YLI_Korkea_ISO_ylivalotettu from "../assets/result-images/V-ISO-YLI_Korkea_ISO_ylivalotettu.jpg"
import VIsoLiianKorkea1 from "../assets/result-images/V-ISO_Liian_korkea_ISO.jpg"
import VIsoLiianKorkea2 from "../assets/result-images/V-ISO_Liian_korkea_ISO2.jpg"

const results = {
  V0: {
    meta: "Oikea valotus",
    img: [V0_Oikea_valotus, V0_Oikea_valotus2],
    text:
      "Congrats! You got an excellent shot of the northern lights. Remember these adjustments when you get the chance to try out northern lights photography in reality. ",
  },
  "V-Ali1": {
    meta: "Kevyt alivalotus",
    img: [VAli1KevytAlivalotus, VAli2KevytAlivalotus],
    text:
      "Almost there but a bit too dark still. Adjust the camera settings a little more and the picture will be perfect! ",
  },
  "V-Ali2": {
    meta: "Voimakas alivalotus",
    img: [VAli2VoimakasAlivalotus],
    text:
      "Nice shot of the starts, but way too dark to catch the northern lights. You will have to adjust the camera settings a bit more. ",
  },
  "V-ISO": {
    meta: "Liian korkea ISO",
    img: [VIsoLiianKorkea1, VIsoLiianKorkea2],
    text:
      "Oops, looks like it’s almost snowing! Adjust the ISO sensitivity to make the shot sharper. ",
  },
  "V-ISO2": {
    meta: "Alivalotus, liian korkea ISO",
    img: [VISO2_Korkea_ISO_alivalotettu],
    text:
      "Your shot is too grainy and dark but don’t be discouraged. Just try again adjusting the camera settings. ",
  },
  "V-ISO-YLI": {
    meta: "Ylivalotus, liian korkea ISO",
    img: [VISO_YLI_Korkea_ISO_ylivalotettu],
    text: "Wow, that’s way too bright which means over exposed shot. ",
  },
  "V-YLI": {
    meta: "Ylivalotus",
    img: [VYli1, VYli2],
    text:
      "Oops, now your shot is little over exposed. Try adjusting the settings again. ",
  },
}

function get_random(list) {
  return list[Math.floor(Math.random() * list.length)]
}

const breakpoints = (
  cssProp = "padding", // the CSS property to apply to the breakpoints
  cssPropUnits = "px", // the units of the CSS property (can set equal to "" and apply units to values directly)
  values = [], // array of objects, e.g. [{ 800: 60 }, ...] <-- 800 (key) = screen breakpoint, 60 (value) = CSS prop breakpoint
  mediaQueryType = "max-width" // media query breakpoint type, i.e.: max-width, min-width, max-height, min-height
) => {
  const breakpointProps = values.reduce((mediaQueries, value) => {
    const [screenBreakpoint, cssPropBreakpoint] = [
      Object.keys(value)[0],
      Object.values(value)[0],
    ]
    return (mediaQueries += `
    @media screen and (${mediaQueryType}: ${screenBreakpoint}px) {
      ${cssProp}: ${cssPropBreakpoint}${cssPropUnits};
    }
    `)
  }, "")
  return css([breakpointProps])
}

const StyledButton = styled.button`
  color: white;
  padding: 14px 28px;
  ${breakpoints("font-size", "", [{ 450: "1.8rem" }])};
  ${breakpoints("padding", "", [{ 1000: "10px 18px" }])};
  ${breakpoints("padding", "", [{ 450: "6px 12px" }])};
  border-radius: 0;
  border: 5px solid white;
  background-color: rgba(255, 255, 255, 0);
  transition: all 0.25s ease-in-out;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  margin: 0;
  padding: 2rem 0;
  position: relative;
  background-color: #060606;
  max-width: 100vw;
  overflow: hidden;
  img,
  video {
    user-select: none;
    pointer-events: none;
  }
  h3 {
    font-size: 3.5rem;
    ${breakpoints("font-size", "", [{ 1450: "2.8rem" }])};
  }
  p {
    font-size: 1.5rem;
    ${breakpoints("font-size", "", [{ 1450: "1.2rem" }])};
  }
  ${breakpoints("justify-content", "", [{ 1080: "flex-start" }])};
  .camerasimulator-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    ${breakpoints("max-width", "", [{ 1080: "1000px" }])};
    ${breakpoints("flex-direction", "", [{ 1080: "column" }])};
    ${breakpoints("padding", "", [{ 1080: "0 2rem" }])};
    .top-left {
      width: 40%;
      position: relative;
      margin: 0;
      margin-right: 2rem;
      display: flex;
      justify-content: flex-end;
      ${breakpoints("width", "", [{ 1080: "100%" }])};
      ${breakpoints("margin-top", "", [{ 1080: "5rem" }])};
      ${breakpoints("margin-right", "", [{ 1080: "0" }])};
      ${breakpoints("justify-content", "", [{ 1080: "center" }])};
    }
    .bottom-right {
      width: calc(60% - 2rem);
      justify-content: flex-start;
      position: relative;
      margin: 0;
      ${breakpoints("width", "", [{ 1080: "100%" }])};
      ${breakpoints("display", "", [{ 1080: "flex" }])};
      ${breakpoints("justify-content", "", [{ 1080: "center" }])};
      ${breakpoints("padding", "", [{ 1080: "2rem 0 3rem 0" }])};
    }
  }
  .button-container {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 1;
  }
  .camera-frame {
    position: relative;
    width: 1080px;
    ${breakpoints("width", "", [{ 1450: "900px" }])};
    padding: 5px;
    .camera-frame-image {
      position: relative;
      width: 100%;
      z-index: 1;
    }
    .capture-button {
      position: absolute;
      top: 80%;
      left: 39%;
      z-index: 1;
      transform: translate(-50%, -50%);
      ${breakpoints("top", "", [{ 550: "70%" }])};
      &:hover {
        transform: translate(-50%, -50%) scale(1.05);
      }
    }
    .video-in-camera-frame {
      position: absolute;
      top: 36%;
      left: 11%;
      width: 56%;
      height: 59%;
      z-index: 0;
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .camera-screen {
    z-index: 1;
    position: relative;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
  }
  .results-screen {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    box-shadow: 0px 0px 20px 5px black;
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 0;
    transition: opacity 0.2s ease-in-out;
    color: white;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    align-items: center;
    ${breakpoints("justify-content", "", [{ 1080: "center" }, {}])};
    .results-container {
      text-align: center;
      padding: 1rem;
    }
    .result-texts-container {
      text-align: center;
      margin-top: 1.2rem;
      margin-bottom: 3rem;
      p {
        width: 100%;
        max-width: 600px;
        margin: 0 auto 1rem auto;
      }
    }
    img {
      width: 100%;
      max-width: 1000px;
      ${breakpoints("max-width", "", [
        { 1450: "550px" },
        { 1080: "600px" },
        {},
      ])};
      margin: 2rem auto 0 auto;
      box-shadow: 0px 0px 5px 1px #262626;
    }
  }
  .intro-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    text-align: center;
    h2,
    h3 {
      margin-bottom: 0;
    }
    h2,
    h3,
    p {
      max-width: 600px;
    }
  }
`

const BgVideoContainer = styled.div`
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  position: fixed;
  overflow: hidden;
  z-index: 0;
  top: 0;
  left: 0;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const StyledControlsContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 750px;
  display: flex;
  text-align: left;
  //background-color: #191919;
  margin: 0;
  padding: 0;
  padding-right: 2rem;
  margin-left: 2rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  ${breakpoints("align-items", "", [{ 1080: "center" }])};
  ${breakpoints("padding-right", "", [{ 1080: "0" }])};
  ${breakpoints("margin-left", "", [{ 1080: "0" }])};
  .controls-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    .control-row {
      padding: 2rem;
      ${breakpoints("padding", "", [{ 1450: "1rem" }])};
      font-size: 2rem;
      .control-head {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        h3 {
          margin-top: 0;
          width: 100%;
          margin: 0;
          padding: 0;
          margin-left: 10px;
          span {
            color: #001f27;
            padding-left: 0.4rem;
          }
        }
        svg {
          height: 3.5rem;
          ${breakpoints("height", "", [{ 1450: "2.8rem" }])};
          path {
            fill: #001f27;
          }
        }
      }
      p {
        max-width: 540px;
        padding-bottom: 0.5em;
        margin-top: 0rem;
        color: #001f27;
      }
      transition: all 0.25s ease-in-out;
      &:hover {
        transition: all 0.25s ease-in-out;
        // background-color: rgba(0,0,0,0.1);
        color: white;
        .control-head {
          color: white;
          transition: all 0.25s ease-in-out;
          svg {
            path {
              fill: white;
              transition: all 0.25s ease-in-out;
            }
          }
          h3 {
            color: white;
            transition: all 0.25s ease-in-out;
            span {
              color: white;
              transition: all 0.25s ease-in-out;
            }
          }
        }
        p {
          color: white;
          transition: all 0.25s ease-in-out;
        }
        .rc-slider {
          & * {
            color: white;
            transition: color 0.25s ease-in-out;
          }
          .rc-slider-track,
          .rc-slider-rail,
          .rc-slider-dot,
          .rc-slider-handle {
            background-color: white;
            transition: background-color 0.25s ease-in-out;
          }
        }
      }
    }
    .rc-slider {
      margin: 2.5rem 0 1rem 0;
      width: 100%;
      .rc-slider-mark-text {
        color: #001f27;
        top: -3rem;
        margin-top: 0;
        font-family: din-condensed, sans-serif;
        font-weight: bolder;
        font-style: normal;
        font-size: 1.5rem;
        ${breakpoints("font-size", "", [{ 1080: "1.2rem" }])};
      }
      .rc-slider-rail {
        background-color: #001f27;
      }
      .rc-slider-track {
        background-color: #001f27;
      }
      .rc-slider-dot {
        position: absolute;
        bottom: -5.5px;
        margin-left: -2px;
        width: 6px;
        height: 12px;
        background-color: #001f27;
        border: none;
        cursor: pointer;
        border-radius: 10%;
        vertical-align: middle;
        z-index: 0;
        &:first-of-type,
        &:last-of-type {
          height: 22px;
          bottom: -10px;
        }
      }
    }
    .button-container {
      margin: 0;
      display: flex;
      flex: row;
      justify-content: space-between;
    }
  }
`
const StyledSlider = props => (
  <Slider
    {...props}
    railStyle={{ height: 8 }}
    trackStyle={{ height: 8 }}
    handleStyle={{
      borderWidth: "5px",
      borderColor: "#001f27",
      height: 24,
      width: 24,
      marginLeft: 0,
      marginTop: -8,
    }}
  ></Slider>
)

const apertures = [2.8, 4, 5.6, 8, 11, 16]

const isos = [100, 200, 400, 800, 1600, 2000, 3200, 6400]

const shutterSpeeds = [30, 15, 8, 4, 2, 1 / 2, 1 / 50, 1 / 125]
const shutterSpeedMarksInitial = [
  "30",
  "15",
  "8",
  "4",
  "2",
  "1/2",
  "1/50",
  "1/125",
]
const shutterSpeedMarksReverted = shutterSpeedMarksInitial.reverse()

export default function App() {
  const [showIntro, setShowIntro] = useState(true)
  const [iso, setISO] = useState(100)
  const [aperture, setAperture] = useState(2.8)
  const [shutterSpeedIndex, setShutterSpeedIndex] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [photoTaken, setPhotoTaken] = useState(false)
  const [photoSrc, setPhotoSrc] = useState(null)
  const [resultText, setResultText] = useState("")
  const [metaText, setMetaText] = useState("")

  const cameraFrameRef = useRef(null)
  const videoRef = useRef(null)
  const bgVideoRef = useRef(null)

  useEffect(() => {
    if (videoRef && bgVideoRef) {
      setIsPlaying(true)
      videoRef.current.play()
      bgVideoRef.current.play()
    }
  })

  const snapPhoto = () => {
    if (photoTaken) {
      setMetaText("")
      setResultText("")
      setPhotoSrc(null)
      setPhotoTaken(false)
      setIsPlaying(true)
      videoRef.current.play()
      bgVideoRef.current.play()
      document.body.style.overflow = "scroll"
    } else {
      const shutterSpeed = shutterSpeedMarksReverted[shutterSpeedIndex]

      const filtered = combinations.filter(
        comb =>
          `${comb.Aperture}` === `${aperture}` &&
          `${comb.Shutterspeed}` === `${shutterSpeed}` &&
          `${comb.ISO}` === `${iso}`
      )

      if (filtered.length > 0) {
        const resultName = filtered[0].KUVA
        const resultImage = get_random(results[resultName].img)
        const resultText = results[resultName].text
        const metaText = results[resultName].meta

        setMetaText(metaText)
        setResultText(resultText)
        setPhotoSrc(resultImage)
      }
      setPhotoTaken(true)
      setIsPlaying(false)
      videoRef.current.pause()
      bgVideoRef.current.pause()
      document.body.style.overflow = "hidden"
    }
  }
  return (
    <StyledApp>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Camera Borealis</title>
        <meta
          name="description"
          content="Camera Simulator for Northern Lights photography"
        />
        <meta
          name="keywords"
          content="Northern Lights, Aurora Borealis, Kota Collective, Photography"
        />
        <meta name="author" content="Kota Collective" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="stylesheet" href="https://use.typekit.net/dsd2xgw.css" />
      </Helmet>

      <BgVideoContainer onContextMenu={e => e.preventDefault()}>
        <video
          ref={bgVideoRef}
          autoPlay={isPlaying}
          loop
          muted
          playsInline
          disablePictureInPicture
          disableRemotePlayback
          oncontextmenu="return false;"
        >
          <source src={VideoBlur}></source>
        </video>
      </BgVideoContainer>

      {showIntro && (
        <div className="intro-screen results-screen">
          <div className="results-container">
            <h2>
              Hey! Nice that you decided to try northernlights photography!
            </h2>
            <p>
              Key to succesful northern lights shot are the correct camera
              settings of aperture (f-stop), shutterspeed and ISO sensitivity.
              The right exposure is the most important thing. Under exposed
              picture is too black and over exposed too white. In northern
              lights photography it is essential to use fast objective and
              expose the shot longer than usual. Too high ISO sensitivity will
              make the picture grainy.
            </p>
            <StyledButton onClick={() => setShowIntro(false)}>
              Okay, let's try it!
            </StyledButton>
          </div>
        </div>
      )}

      {photoTaken && (
        <div className="screen results-screen">
          <div className="results-container">
            <img src={photoSrc} alt={metaText}></img>
            <div className="result-texts-container">
              <p>{resultText}</p>
              <StyledButton onClick={snapPhoto}>TRY AGAIN</StyledButton>
            </div>
          </div>
        </div>
      )}

      <div className="camerasimulator-container">
        <div className="top-left split-content">
          <StyledControlsContainer>
            <div className="controls-wrapper">
              <div className="control-row">
                <div className="control-head">
                  <ApertureIcon />
                  <h3>
                    <span>f{aperture}</span>
                  </h3>
                </div>
                <p>
                  Aperture controls the brightness of the image and the amount
                  of light passed on to sensor
                </p>
                <StyledSlider
                  marks={apertures}
                  min={0}
                  max={apertures.length - 1}
                  step={1}
                  onChange={value => setAperture(apertures[value])}
                ></StyledSlider>
              </div>

              <div className="control-row">
                <div className="control-head">
                  <IsoIcon />
                  <h3>
                    <span>{iso}</span>
                  </h3>
                </div>
                <p>
                  ISO controls the sensitivity of the sensor, higher ISO
                  introduces grain to image
                </p>
                <StyledSlider
                  marks={isos}
                  min={0}
                  max={isos.length - 1}
                  step={1}
                  onChange={value => setISO(isos[value])}
                ></StyledSlider>
              </div>

              <div className="control-row">
                <div className="control-head">
                  <ExposureIcon />
                  <h3>
                    <span>
                      {shutterSpeedMarksReverted[shutterSpeedIndex]} s
                    </span>
                  </h3>
                </div>
                <p>
                  Exposure time controls the amount of light passed on to
                  sensor, our camera is on a tripod to allow for longer
                  exposures
                </p>
                <StyledSlider
                  marks={shutterSpeedMarksReverted}
                  min={0}
                  max={shutterSpeeds.length - 1}
                  step={1}
                  onChange={value => setShutterSpeedIndex(value)}
                ></StyledSlider>
              </div>
            </div>
          </StyledControlsContainer>
        </div>
        <div className="bottom-right split-content">
          <div className="camera-frame" ref={cameraFrameRef}>
            {/*  <img ></img> */}
            <StaticImage
              src="../assets/kamera.png"
              alt="Camera frame"
              backgroundColor="transparent"
              loading="eager"
              layout="fullWidth"
              placeholder="tracedSvg"
              className="camera-frame-image"
              formats={["AUTO", "png", "webp", "avif"]}
            />
            <StyledButton className="capture-button" onClick={snapPhoto}>
              TAKE A PHOTO
            </StyledButton>
            <div
              className="video-in-camera-frame"
              onContextMenu={e => e.preventDefault()}
            >
              <video
                ref={videoRef}
                autoPlay={isPlaying}
                loop
                muted
                playsInline
                disablePictureInPicture
                disableRemotePlayback
                style={{ transform: `translate(0, 0) scale(1})` }}
              >
                <source src={Video}></source>
              </video>
            </div>
          </div>
        </div>
      </div>
    </StyledApp>
  )
}
