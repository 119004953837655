import React from "react"

export const ApertureIcon = ({ width, height }) =>
<svg width={width} height={height} viewBox="0 0 343.07 343.51">
  <g>
    <path d="M0,163.6c1-7.7,1.86-15.45,3.17-23.1,6-34.82,22.4-64.24,46.55-89.64,2.87-3,6.16-4.29,10.32-3a750,750,0,0,1,97.3,38,23.53,23.53,0,0,1,2.74,2A728.54,728.54,0,0,0,2.22,199L0,180.55Z"/>
    <path d="M104.68,225.51c28.5,15,56.26,30.91,85.2,44.31s59.07,24.23,88.87,36.26c.13-.23,0,.48-.47.79-25.45,17.88-52.92,30.84-84.07,35.17-22.36,3.11-44.34,1.09-66.13-4.26-3.91-1-6.88-2.61-7.87-7.15-7.4-34.08-13-68.43-15.52-103.24C104.63,226.58,104.68,225.77,104.68,225.51Z"/>
    <path d="M63.68,37.29c10.13-6.13,20-12.84,30.44-18.29C133-1.24,173.77-4.82,216,5.93c3.57.91,6,2.51,6.81,6.27,7.76,34.56,13.44,69.44,15.78,104.81a1.43,1.43,0,0,1-.2.82c-28.19-14.8-56.17-30.17-84.77-44.31s-58.86-25-89.39-34.65Z"/>
    <path d="M341.18,144.33c.65,6,1.44,10.84,1.66,15.72,1.45,31.24-3.79,61.16-18.14,89.18A173.87,173.87,0,0,1,293,293c-2.8,2.81-6,3.88-10,2.4-25.76-9.66-51.59-19.14-77.29-28.94-7.54-2.87-14.75-6.6-23.27-10.46A732.91,732.91,0,0,0,341.18,144.33Z"/>
    <path d="M110.56,331.8A154,154,0,0,1,71,310.51C39.35,287.32,18.18,256.8,6.66,219.36,4.77,213.2,5.52,209,10.52,204.51A779.64,779.64,0,0,1,88,142.74c1.32-.92,2.8-1.61,5.28-3A722.24,722.24,0,0,0,110.56,331.8Z"/>
    <path d="M234.44,11.07c11.22,6.31,22.85,12,33.58,19.07,35.27,23.16,57.16,56.14,69.06,96.22,1.35,4.55.61,7.61-3,11a668.58,668.58,0,0,1-78.75,63.26c-1.33.91-2.71,1.73-5.23,3.33a726.52,726.52,0,0,0-17-191.14Z"/>
  </g>
</svg>

export const IsoIcon = ({ width, height })  => 
<svg width={width} height={height} viewBox="0 0 515.29 230.92">
  <g>
    <path d="M0,36.3c.89-3.71,1.56-7.49,2.71-11.12C7.77,9.27,24.29-1.28,40.54.88,58.73,3.29,72.25,16.93,72.4,35.2q.61,79.91,0,159.82a34.35,34.35,0,0,1-31.77,34.16C20.19,230.5,5,218.86,1.55,199.31c-.37-2.13-1-4.2-1.55-6.3Z"/>
    <path d="M396.15,229.52C330.89,228.8,278,176.7,278.47,113.71,279,50.49,333.32-.72,399.1,0c64.42.71,117.17,53.43,116.18,116.13S460.38,230.23,396.15,229.52Zm.69-62.14c3.79-.57,7.67-.79,11.36-1.77C435,158.5,450.32,130,443,100.93c-5.76-22.84-22.82-37.86-43.68-38.45C373,61.73,354,79.06,349.77,107.71,345.2,138.92,367.56,167.12,396.84,167.38Z"/>
    <path d="M171.59,230.92c-16.8-2.75-33.83-4.54-50.29-8.64-8.74-2.18-17.19-7.43-24.49-13C84.51,200,82,180.77,90.06,167.56c6.8-11.16,17.19-15.92,30.31-13.09,16.67,3.6,33.1,8.37,49.88,11.26,6.63,1.15,14-.94,20.83-2.55,2.2-.52,5.06-4.23,5-6.43s-3.08-5.35-5.42-6.07c-14.47-4.39-29.13-8.11-43.67-12.25a202.83,202.83,0,0,1-20.39-6.61c-39.46-15.89-52.25-58.77-28-93.73C111,20.26,128.7,10.17,149.34,4.94c29.9-7.59,59.12-5.94,86.74,8.63C256.4,24.3,261,49.34,246,65.13c-10.42,11-23.24,9.66-36.2,6.41-9.87-2.47-19.53-5.82-29.46-7.92a54.19,54.19,0,0,0-17.15-.5c-2.23.26-5.31,3.47-5.71,5.74-.28,1.61,3,5,5.2,5.68,15.55,4.62,31.31,8.51,46.86,13.12A160.63,160.63,0,0,1,232.7,96.3c34.9,16.58,47,55.62,27.65,89-16.85,29-43.7,41.31-76.08,43.74-4.11.31-8.27.05-12.41.05Z"/>
  </g>
</svg>

export const ExposureIcon = ({ width, height })  => 
<svg width={width} height={height} viewBox="0 0 351.38 351.58">
  <g>
    <path d="M170.89,0h8c1.94.33,3.87.78,5.82,1,5.78.58,11.62.7,17.33,1.63,49,8,88,32.14,116.9,72.51a164.49,164.49,0,0,1,29.57,74.27c1.14,8.35,1.93,16.75,2.89,25.13v3c-1,8.54-1.68,17.12-3,25.62-6.85,45.24-28.44,82.24-64.14,110.53-40.89,32.41-87.81,43.67-139,35.24-46.76-7.71-84.12-31.58-112-69.84C5,240.19-4.75,196.43,2.11,149.24c5.9-40.67,24.45-75,55-102.85C85,20.9,117.68,6.18,155.16,1.9,160.41,1.3,165.65.63,170.89,0ZM35.26,176c-1.7,74.69,59.32,141.08,140.14,140.78,80.37-.28,137.59-63.3,140.2-135.45,2.92-80.48-60.26-146.14-140.63-146S33.52,101.33,35.26,176Z"/>
    <path d="M157.94,136.34c0-13.78.14-27.56-.08-41.33-.07-4.82,1.76-6.49,6.45-6.33,7.3.26,14.61,0,21.91.08,6.09,0,6.57.49,6.58,6.52,0,21.91.17,43.83-.09,65.74-.06,5.56,1.47,9.67,5.53,13.59,12.78,12.33,25.11,25.12,37.82,37.53,3.27,3.19,3.41,5.34,0,8.47-5.84,5.41-11.39,11.15-16.93,16.87-2.33,2.4-4.18,2.61-6.65.12-17.19-17.31-34.55-34.46-51.61-51.91-1.82-1.86-2.76-5.29-2.81-8C157.79,163.9,157.94,150.12,157.94,136.34Z"/>
  </g>
</svg>
